import spacetime from 'spacetime';
const commentMixin = {
	methods: {
		$_commentMixin_unreadIssues(issues) {
			if (!issues || issues === []) {
				return 0;
			}

			return issues.filter(issue =>
				this.$_commentMixin_hasUnreadComments(issue),
			).length;
		},
		$_commentMixin_hasUnreadComments(issue) {
			if (!issue || issue.comments === []) {
				return false;
			}

			return issue.comments.some(comment => isUnread(comment, issue));
		},
	},
};

// Private functions
function isUnread(comment, issue) {
	if (!issue || !issue.lastAccessed || issue.comments === []) {
		return false;
	}

	return (
		!comment.createdByUser &&
		spacetime(comment.createdAt.date).time(comment.createdAt.time).isAfter(
			spacetime(issue.lastAccessed.date).time(issue.lastAccessed.time)
		)
	);
}

export default commentMixin;
