<template>
	<div id="loggedInChecker" />
</template>

<script>
import { LOGGED_IN, apolloClient } from '@/graphql';

export default {
	name: 'LoggedInChecker',
	mounted() {
		const data = apolloClient.readQuery({ query: LOGGED_IN });
		if (!data || !data.me.loggedIn) {
			console.log('Not logged in, redirecting to login');
			this.$router.push({ name: 'login' });
		}
	},
};
</script>

<style></style>
