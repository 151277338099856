<template>
	<v-container>
		<v-scroll-x-reverse-transition leave-absolute>
			<v-form
				v-show="!emailChecked"
				class="pa-0"
				@submit.prevent="submitEmail()"
			>
				<v-card-title tag="h1">Registrera ett konto</v-card-title>
				<v-container class="pa-4">
					<a-form-input>
						<v-text-field
							v-model="email"
							:error-messages="emailErrors"
							:disabled="disabled"
							label="E-postadress"
							type="text"
							required
						/>
					</a-form-input>
				</v-container>
				<v-card-actions>
					<v-flex grow />
					<v-btn
						:loading="loading"
						:disabled="loading"
						color="secondary"
						type="submit"
					>
						Nästa
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-scroll-x-reverse-transition>
		<v-scroll-x-reverse-transition leave-absolute>
			<v-form v-show="emailChecked" class="pa-0" @submit.prevent="register()">
				<v-container class="pa-4">
					<a-form-input>
						<v-text-field
							v-model="email"
							style=""
							:error-messages="emailErrors"
							disabled
							background-color="grey lighten-4"
							type="text"
							required
						>
							<template #prepend-inner>
								<v-icon color="success">mdi-check</v-icon>
							</template>
						</v-text-field>
						<span class="mx-auto">Välj ett lösenord</span>
						<v-text-field
							v-model="password"
							:type="showPassword ? 'text' : 'password'"
							:error-messages="passwordErrors"
							:disabled="disabled"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							label="Lösenord"
							required
							counter
							@click:append="showPassword = !showPassword"
						/>
					</a-form-input>
				</v-container>
				<v-card-actions>
					<v-flex grow />
					<v-btn
						:loading="loading"
						:disabled="loading"
						type="submit"
						color="secondary"
					>
						Registrera
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-scroll-x-reverse-transition>
	</v-container>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import { REGISTER_USER, CHECK_NEW_USER_EMAIL } from '@/graphql';
import AFormInput from '@/components/FormInput';

export default {
	components: {
		AFormInput,
	},
	props: {
		disabled: {
			type: Boolean,
		},
	},
	data: () => ({
		email: '',
		password: '',
		loading: false,
		showPassword: false,
		emailChecked: false,
		emailErrors: [],
	}),
	computed: {
		passwordErrors() {
			const errors = [];
			if (!this.$v.password.$dirty) return errors;
			if (!this.$v.password.required) {
				errors.push('Lösenord krävs');
			}

			if (!this.$v.password.minLength) {
				errors.push('Lösenordet måste vara minst 6 tecken långt.');
			}

			return errors;
		},
	},
	methods: {
		async submitEmail() {
			this.emailChecked = await this.checkEmail();
		},
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			if (!this.$v.$invalid) this.register();
		},
		async checkEmail() {
			this.emailErrors = [];
			this.$v.$reset();
			this.$v.email.$touch();
			if (!this.$v.email.required) {
				this.emailErrors.push('E-postadress krävs');
				return false;
			}

			if (!this.$v.email.email) {
				this.emailErrors.push('Måste vara en giltig e-postadress');
				return false;
			}

			this.loading = true;
			const { data } = await this.$apollo
				.query({
					query: CHECK_NEW_USER_EMAIL,
					variables: {
						input: {
							email: this.email,
						},
					},
					fetchPolicy: 'no-cache',
				})
				.catch(error => {
					this.emailErrors.push(error.graphQLErrors[0].message);
					this.loading = false;
					return false;
				});
			this.loading = false;

			if (data?.exists) {
				return true;
			}

			// Should not happen, the query returns either true or throws an error
			this.emailErrors.push('Någonting gick fel');
			return false;
		},
		async register() {
			if (!(await this.checkEmail())) return;
			const email = this.email.toLowerCase();
			const { password } = this;
			const { loading } = this;
			this.loading = true;

			await this.$apollo
				.mutate({
					mutation: REGISTER_USER,
					variables: {
						input: {
							email,
							password,
						},
					},
				})
				.then(({ data: { registerUser } }) => {
					if (registerUser) {
						this.$emit('registration-success', this.email);
						this.email = '';
						this.password = '';
						this.loading = false;
						this.checkEmail = false;
					} else {
						this.$toast.error('Registrering misslyckades.');
					}

					this.$v.$reset();
				})
				.catch(error => {
					this.email = email;
					this.password = password;
					this.loading = loading;
					this.$v.$reset();
					this.passwordErrors.push(error.graphQLErrors[0].message);
				});
			this.$root.$emit('logged_in');
		},
	},
	validations: {
		email: {
			required,
			email,
		},
		password: {
			required,
			minLength: minLength(6),
		},
	},
};
</script>
