<template>
	<v-container v-show="!loggedIn" class="outer-container">
		<div class="login-card-flex-container flex-grow-0">
			<v-fade-transition hide-on-leave leave-absolute>
				<a-login-card v-if="form === 'login'" :logo-url="logoUrl" />
			</v-fade-transition>
			<v-fade-transition hide-on-leave leave-absolute>
				<a-register-card v-if="form === 'register'" :logo-url="logoUrl" />
			</v-fade-transition>
		</div>
		<div class="d-flex flex-column flex-grow-1 align-start">
			<v-card-text class="text-center">
				{{
					{
						register: 'Har du redan ett konto?',
						login: 'Har du inget konto än?',
					}[form]
				}}
			</v-card-text>
			<v-btn
				class="mx-auto"
				@click="form = { register: 'login', login: 'register' }[form]"
			>
				<span>{{
					{ register: 'Logga in', login: 'Registrera dig' }[form]
				}}</span>
			</v-btn>
		</div>
		<a-small-contact-info
			v-if="showContactInfo"
			:telephone="companyTelephone"
			:email="companyEmail"
			class="mt-7"
		/>
		<v-btn
			color="secondary"
			class="mt-5 mx-auto"
			small
			text
			outlined
			@click="showCookiePopup()"
		>
			Uppdatera cookieinställningar
		</v-btn>
	</v-container>
</template>

<script>
import Router from 'vue-router';
import ALoginCard from '@/components/LoginCard';
import ARegisterCard from '@/components/RegisterCard';
import ASmallContactInfo from '@/components/SmallContactInfo';
const { isNavigationFailure, NavigationFailureType } = Router;
import { LOGGED_IN, LOGIN_VIEW } from '@/graphql';
export default {
	components: {
		ALoginCard,
		ARegisterCard,
		ASmallContactInfo,
	},
	data: () => ({
		loggedIn: true,
		form: 'register',
		counter: 0,
		logoUrl: '',
		companyEmail: '',
		companyTelephone: '',
	}),
	computed: {
		showContactInfo() {
			return Boolean(this.companyEmail) || Boolean(this.companyTelephone);
		},
	},
	mounted() {
		this.form = this.$route.name === 'login' ? 'login' : 'register';
	},
	apollo: {
		company: {
			query: LOGIN_VIEW,
			update({ company }) {
				if (company) {
					this.logoUrl = company.logoUrl;
					this.companyEmail = company.email;
					this.companyTelephone = company.tel;
				}
			},
		},
		loggedIn: {
			query: LOGGED_IN,
			update({ me }) {
				// Using a counter here to only redirect once.
				// It is triggered twice.
				if (me.loggedIn && this.counter === 0) {
					this.counter++;
					this.$router.push({ name: 'home' }).catch(error => {
						if (isNavigationFailure(error, NavigationFailureType.redirected)) {
							console.log(
								'attempted navigation from "/" to "/home", was redirected',
							);
						} else {
							throw error;
						}
					});
				}

				return me.loggedIn;
			},
		},
	},
	methods: {
		showCookiePopup() {
			this.$root.$emit('update-cookie-settings');
		},
	},
	metaInfo: {
		title: 'Välkommen till kundportalen',
	},
};
</script>

<style>
.outer-container {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	max-width: 470px;
}
.login-card-flex-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}
</style>
