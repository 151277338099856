import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
	register('/service-worker.js', {
		ready() {
			console.log('App is being served from cache by a service worker.');
		},
		registered(registration) {
			// Check for updates every hour
			setInterval(() => registration.update(), 1000 * 60 * 60);
			registration.update();
			console.log('Service worker has been registered.');
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			console.log('New content is downloading.');
		},
		updated(registration) {
			console.log('New content is available; please refresh.');
			document.dispatchEvent(new CustomEvent('swu', { detail: registration }));
		},
		offline() {
			console.log(
				'No internet connection found. App is running in offline mode.',
			);
		},
		error(error) {
			console.error('Error during service worker registration:', error);
		},
	});
}
