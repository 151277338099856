import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
	createApolloClient,
	restartWebsockets,
} from 'vue-cli-plugin-apollo/graphql-client';
import { onError } from 'apollo-link-error';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
// const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const httpEndpoint = process.env.VUE_APP_API_HTTP;

const COMPANY_NOT_FOUND_REGEX = /company not found/i;

// Config
const defaultOptions = {
	// You can use `https` for secure connection (recommended in production)
	httpEndpoint,
	// You can use `wss` for secure connection (recommended in production)
	// Use `null` to disable subscriptions
	// wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:8000/',
	wsEndpoint: null,
	// LocalStorage token
	// tokenName: AUTH_TOKEN,
	// Enable Automatic Query persisting with Apollo Engine
	persisting: false,
	// Use websockets for everything (no HTTP)
	// You need to pass a `wsEndpoint` for this to work
	websocketsOnly: false,
	// Is being rendered on the server?
	ssr: false,

	// Override default apollo link
	// note: don't override httpLink here, specify httpLink options in the
	// httpLinkOptions property of defaultOptions.
	// link: myLink

	// Override the way the Authorization header is set
	// getAuth: (tokenName) => ...

	// Additional ApolloClient options
	// apollo: { ... },

	// Client local data
	// onCacheInit: cache => {
	//   console.log('query', LOGGED_IN);
	//   const alreadyLoggedIn = cache.readQuery({ query: LOGGED_IN });
	//   console.log('already', alreadyLoggedIn);

	//   const data = {
	//     me: {
	//       __typename: 'User',
	//       loggedIn: false,
	//     },
	//   };
	//   cache.writeData({ data });
	// },
};

// Call this in the Vue app file
// onCompanyNotFound is called when a gql response is received indicating
// that the company in question was not found (i.e. if the customer portal)
// does not exist.
export function createProvider(options = {}, onCompanyNotFound = () => {}) {
	const errorLink = onError(({ networkError }) => {
		const networkErrorCode = networkError?.statusCode;
		if (
			networkErrorCode === 404 &&
			COMPANY_NOT_FOUND_REGEX.test(networkError.bodyText)
		) {
			// Handle company not found errors
			onCompanyNotFound(networkError);
		}
	});
	// Create apollo client
	const { apolloClient, wsClient } = createApolloClient({
		...defaultOptions,
		...options,
		link: errorLink,
	});
	apolloClient.wsClient = wsClient;

	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient,
		defaultOptions: {
			$query: {
				fetchPolicy: 'cache-and-network',
				// NotifyOnNetworkStatusChange: true,
			},
		},
		errorHandler(error) {
			console.log(
				'%cError',
				'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
				error.message,
			);
		},
	});

	return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient) {
	// Token arg removed
	// if (typeof localStorage !== 'undefined' && token) {
	//   localStorage.setItem(AUTH_TOKEN, token);
	// }
	if (apolloClient.wsClient) {
		restartWebsockets(apolloClient.wsClient);
	}

	try {
		await apolloClient.resetStore();
	} catch (error) {
		console.log(
			'%cError on cache reset (login)',
			'color: orange;',
			error.message,
		);
	}
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
	// If (typeof localStorage !== 'undefined') {
	//   localStorage.removeItem(AUTH_TOKEN);
	// }
	if (apolloClient.wsClient) {
		restartWebsockets(apolloClient.wsClient);
	}

	try {
		await apolloClient.resetStore();
	} catch (error) {
		console.log(
			'%cError on cache reset (logout)',
			'color: orange;',
			error.message,
		);
	}
}
