const generalMixin = {
	methods: {
		$_generalMixin_deviceIsIphoneXOrLater(navigator, screen) {
			// Checks if device is iPhone X or later
			if (Boolean(navigator.platform) && /iPhone/.test(navigator.platform)) {
				if (
					(screen.availHeight === 812 && screen.availWidth === 375) || // IPhone X, Xs, 11 Pro, 12 Mini
					(screen.availHeight === 896 && screen.availWidth === 414) || // IPhone Xr, Xs Max, 11, 11 Pro Max,
					(screen.availHeight === 844 && screen.availWidth === 390) || // IPhone 12, 12 Pro
					(screen.availHeight === 926 && screen.availWidth === 428) // IPhone 12 Pro Max
				) {
					return true;
				}
			}

			return false;
		},
	},
};

export default generalMixin;
