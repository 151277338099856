<template>
	<v-app>
		<a-updater />
		<a-top-nav />
		<a-cookie-popup />
		<v-content class="container limited-width">
			<v-container fill-height fluid class="pa-0">
				<transition name="fade-transition" mode="out-in">
					<router-view />
				</transition>
			</v-container>
		</v-content>
		<a-bottom-nav />
		<a-logged-in-checker v-if="authenticatedView" />
	</v-app>
</template>

<script>
import ATopNav from '@/components/TheTopNav';
import ABottomNav from '@/components/TheBottomNav';
import ALoggedInChecker from '@/components/LoggedInChecker';
import AUpdater from '@/components/TheUpdater';
import ACookiePopup from '@/components/CookiePopup';
import { COMPANY_THEME, COMPANY } from '@/graphql';

export default {
	name: 'App',
	components: {
		ATopNav,
		ABottomNav,
		ALoggedInChecker,
		AUpdater,
		ACookiePopup,
	},
	data: () => ({
		query: COMPANY_THEME,
		favicon: null,
		company: null,
	}),
	computed: {
		authenticatedView() {
			const view = this.$router.currentRoute.name;
			return (
				view !== 'login' &&
				view !== 'register' &&
				view !== 'resetPassword' &&
				view !== 'verifyEmail' &&
				view !== 'noCompany'
			);
		},
	},
	apollo: {
		// This query is also for checking if the company exists.
		// The no-cache property ensures that the apollo error link
		// is checked every time this is called.
		companyTheme: {
			query: COMPANY_THEME,
			fetchPolicy: 'no-cache',
			update({ companyTheme, company }) {
				this.updateTheme(companyTheme);
				this.updateIcon(company);
			},
		},
		// Using no-cache is fine here since this query is only run once
		// per refresh, not during navigation etc. This ensures the latest
		// logo is used whenever the app is installed on an iOS device.
		company: {
			query: COMPANY,
			fetchPolicy: 'no-cache',
			update({ company }) {
				return company;
			},
		},
	},
	metaInfo() {
		let appleIconLink = 'favicon.ico';
		if (this.company?.iconSmallUrl) {
			appleIconLink = this.company.iconSmallUrl;
		} else if (this.company?.iconLargeUrl) {
			appleIconLink = this.company.iconLargeUrl;
		}

		return {
			title: `Kundportalen`,
			titleTemplate: `%s | ${this.company?.name} Kundportal`,
			meta: [
				{
					name: 'description',
					content:
						'Via kundportalen kan du se och hantera dina bokningar, sköta kontakten med oss, och se dina fakturor. Registrera dig på under 2 minuter!',
				},
				{
					name: 'theme-color',
					content: this.$vuetify.theme.themes.light.primary,
				},
			],
			link: [
				{
					rel: 'manifest',
					href: `${process.env.VUE_APP_API_HTTP}/manifest.json`,
				},
				{
					rel: 'icon',
					href: this.favicon || 'favicon.ico',
				},
				{
					rel: 'apple-touch-icon',
					href: appleIconLink,
				},
			],
		};
	},
	methods: {
		updateTheme(companyTheme) {
			const {
				primary,
				secondary,
				accent,
				error,
				info,
				warning,
				success,
			} = companyTheme;
			const theme = {
				primary,
				secondary,
				accent,
				error,
				info,
				warning,
				success,
			};

			this.$vuetify.theme.defaults = theme;
			this.$vuetify.theme.themes.light = theme;
			this.$vuetify.theme.themes.dark = theme;

			return companyTheme;
		},
		updateIcon(company) {
			if (!company) {
				return;
			}

			const { iconSmallUrl } = company;
			if (iconSmallUrl) {
				this.favicon = iconSmallUrl;
			}
		},
	},
};
</script>

<style scoped>
.limited-width {
	max-width: 840px;
	width: 100%;
}
</style>
