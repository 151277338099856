<template>
	<div class="text-center">
		<div class="text-subtitle-1">
			Kontakta oss
		</div>
		<div v-if="telephone" class="text-body-2">
			<a :href="'tel:' + telephone" class="text-decoration-none">
				{{ telephone }}
			</a>
		</div>
		<div v-if="email" class="text-body-2">
			<a
				:href="'mailto:' + email"
				target="_blank"
				rel="noopener noreferrer"
				class="text-decoration-none"
			>
				{{ email }}
			</a>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		email: {
			type: String,
			default: null,
		},
		telephone: {
			type: String,
			default: null,
		},
	},
};
</script>
