<template>
	<div />
</template>

<script>
import { VERIFY_EMAIL } from '@/graphql';

export default {
	props: {
		token: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		verificationFailed: false,
	}),
	mounted() {
		if (this.token) {
			this.$apollo
				.mutate({
					mutation: VERIFY_EMAIL,
					variables: {
						input: {
							token: this.token,
						},
					},
				})
				.then(() => {
					this.$gtm.trackEvent({
						event: 'sign_up',
					});
					this.loading = false;
					this.$toast.success('Verifiering av e-postadress lyckades.');
					this.$router.push({ name: 'login' });
				})
				.catch(error => {
					this.$toast.error(error.graphQLErrors[0].message);
					this.$router.push({ name: 'login' });
				});
		} else {
			this.$toast.error('Ingen token att verifiera angavs.');
			this.$router.push({ name: 'login' });
		}
	},
};
</script>

<style></style>
