<template>
	<v-container v-if="!loggedIn">
		<v-card>
			<v-img :src="media" :aspect-ratio="16 / 9" class="white--text" />

			<a-register-form v-if="!registrationComplete" @registration-success="showSuccessMessage" />
			<v-container v-else>
				<v-icon color="success" x-large class="d-flex"> mdi-check-circle-outline </v-icon>
				<v-card-title class="justify-center pt-0">
					Registrering klar!
				</v-card-title>
				<v-card-text>
					En verifieringslänk har skickats till {{email}}. <br/>
					Var god kontrollera din e-post.
				</v-card-text>
			</v-container>
		</v-card>
	</v-container>
</template>

<script>
import ARegisterForm from '@/components/RegisterForm';

export default {
	name: 'RegisterCard',
	components: { ARegisterForm },
	props: {
		logoUrl: {
			type: String,
			default: () => '',
		},
		loggedIn: {
			type: Boolean,
		},
	},
	data: () => ({
		registrationComplete: false,
		email: '',
		counter: 0,
	}),
	methods: {
		showSuccessMessage(email) {
			this.email = email;
			this.registrationComplete = true;
		},
	},
	computed: {
		media() {
			if (this.logoUrl === '') {
				return 'https://source.unsplash.com/YXemfQiPR_E';
			}

			return this.logoUrl;
		},
	},
};
</script>

<style></style>
