export * from './mutations';
export * from './queries';
import router from '../router';
import localSchema from './localSchema/schema.gql';
import { createProvider } from './vue-apollo';

const onCompanyNotFound = () => {
	if (router.currentRoute.name !== 'noCompany') {
		router.push({ name: 'noCompany' });
	}
};

export const apolloProvider = createProvider(
	{
		httpLinkOptions: {
			credentials: 'include',
			headers: {
				'X-Section': 'customer',
			},
		},
		apollo: { typeDefs: localSchema, resolvers: {} },
	},
	onCompanyNotFound,
);

export const { cache } = apolloProvider.clients.defaultClient;
export const apolloClient = apolloProvider.clients.defaultClient;
