<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="500" persistent>
			<v-card>
				<v-btn class="float-right ma-1" fab small text @click="close()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-card-title style="word-break: break-word">
					Den här webbsidan använder cookies
				</v-card-title>

				<v-card-text class="mt-1">
					Läs vår
					<a
						href="https://docs.google.com/document/d/1UnymZjS66Z6PIQcd78kThs1AD8Xo92fVn3XN2aOQi-4/edit?usp=sharing"
					>integritetspolicy</a>
					för information om hur vi hanterar din data.
					<br />
					Vi använder följande kategorier av cookies:
				</v-card-text>

				<v-card-text>
					<v-switch
						v-model="cookies_neccessary"
						label="Nödvändiga cookies"
						color="primary"
						disabled
						inset
						class="ma-0"
						hide-details
					/>
				</v-card-text>
				<v-card-text>
					Nödvändiga cookies är cookies som behövs för att sidan ska fungera
					korrekt.
				</v-card-text>

				<v-card-text class="py-0">
					<v-switch
						v-model="cookies_analytics"
						label="Analyscookies"
						color="primary"
						inset
					/>
				</v-card-text>
				<v-card-text class="pb-0">
					Analyscookies är cookies som används för att analysera användandet av
					sidan. De hjälper oss utveckla webbplatsen för att kunna erbjuda en
					bättre upplevelse.
				</v-card-text>

				<v-card-actions>
					<v-container class="pa-0 d-none d-sm-flex">
						<v-row dense>
							<v-spacer />
							<v-col>
								<v-btn color="secondary" text outlined @click="acceptAll()">
									Godkänn alla
								</v-btn>
							</v-col>
							<v-col>
								<v-btn color="secondary" text outlined @click="rejectAll()">
									Avvisa alla
								</v-btn>
							</v-col>
							<v-col>
								<v-btn color="secondary" @click="saveSettings()">
									Spara val
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
					<v-container class="pa-0 d-sm-none">
						<v-btn
							class="my-3"
							color="secondary"
							text
							outlined
							block
							@click="acceptAll()"
						>
							Godkänn alla
						</v-btn>
						<v-btn
							class="my-3"
							color="secondary"
							text
							outlined
							block
							@click="rejectAll()"
						>
							Avvisa alla
						</v-btn>
						<v-btn class="my-3" color="secondary" block @click="saveSettings()">
							Spara val
						</v-btn>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	data: () => ({
		cookies_analytics: true,
		cookies_neccessary: true,
		hasAnswered: true,
		updateSettings: false,
	}),
	computed: {
		dialog() {
			return !this.hasAnswered || this.updateSettings;
		},
	},
	mounted() {
		// Check for false to default to true on null value returned so that it's prechecked when opening first time
		this.cookies_analytics =
			localStorage.getItem('cookies_analytics') !== 'false';
		this.hasAnswered =
			localStorage.getItem('hasAnsweredCookiePrompt') === 'true';

		this.$root.$on('update-cookie-settings', () => {
			this.updateSettings = true;
		});

		if (this.cookies_analytics && this.hasAnswered) {
			this.$gtm.enable(true);
		} else {
			this.$gtm.enable(false);
		}
	},
	methods: {
		saveSettings() {
			localStorage.setItem('cookies_analytics', this.cookies_analytics);
			localStorage.setItem('hasAnsweredCookiePrompt', true);
			this.hasAnswered = true;
			this.updateSettings = false;
			if (this.cookies_analytics) {
				this.$gtm.enable(true);
				console.log('GTM enabled');
			} else {
				this.$gtm.enable(false);
				console.log('GTM disabled');
			}
		},
		acceptAll() {
			this.cookies_analytics = true;
			this.saveSettings();
		},
		rejectAll() {
			this.cookies_analytics = false;
			this.saveSettings();
		},
		close() {
			// Does not save to localStorage, so that the popup still can show up again on reload
			this.hasAnswered = true;
			this.updateSettings = false;
		},
	},
};
</script>
