<template>
	<transition name="fade">
		<apollo-query :query="query" :update="data => data.me">
			<template v-slot="{ result: { loading, data } }">
				<v-bottom-navigation
					v-if="data && data.loggedIn"
					v-bind:style="[getNavigationStyle]"
					app
					grow
					class="px-1"
				>
					<div class="container limited-width d-flex">
						<v-btn
							v-for="item in items"
							:key="item.index"
							:to="item.to"
							class="nav-button"
							color="secondary"
							exact
							text
						>
							<span class="nav-button-text">{{ item.text }}</span>
						<v-badge overlap :value="item.displayBadge && numberOfUnreadIssues" :content="numberOfUnreadIssues">
							<v-icon>{{ isActive(item) ? item.activeIcon : item.icon }}</v-icon>
						</v-badge>
						</v-btn>
					</div>
				</v-bottom-navigation>
			</template>
		</apollo-query>
	</transition>
</template>

<script>
import spacetime from 'spacetime'
import { LOGGED_IN, ISSUES } from '@/graphql';
import generalMixin from '@/mixins/generalMixin';
import commentMixin from '@/mixins/commentMixin';

export default {
	mixins: [generalMixin, commentMixin],
	data: () => ({
		query: LOGGED_IN,
		activeBtn: 0,
		numberOfUnreadIssues: 0,
		lastUpdateAt: spacetime.now(),
		updateUnreadTimeThreshold: 300*1000, //5 minutes
		items: [
			{
				index: 0,
				icon: 'mdi-home-outline',
				activeIcon: 'mdi-home',
				text: 'Hem',
				to: { name: 'home' },
				displayBadge: false,
			},
			{
				index: 1,
				icon: 'mdi-calendar-month-outline',
				activeIcon: 'mdi-calendar-month',
				text: 'Bokningar',
				to: { name: 'bookings' },
				displayBadge: false,
			},
			{
				index: 2,
				icon: 'mdi-cash',
				activeIcon: 'mdi-cash',
				text: 'Fakturor',
				to: { name: 'invoices' },
				displayBadge: false,
			},
			{
				index: 3,
				icon: 'mdi-email-outline',
				activeIcon: 'mdi-email',
				text: 'Inkorg',
				to: { name: 'issues' },
				displayBadge: true,
			},
			{
				index: 4,
				icon: 'mdi-account-outline',
				activeIcon: 'mdi-account',
				text: 'Mitt konto',
				to: { name: 'account' },
				displayBadge: false,
			},
		],
	}),
	computed: {
		getNavigationStyle() {
			const px = this.$_generalMixin_deviceIsIphoneXOrLater(navigator, screen)
				? '75px'
				: '56px';
			return { height: px };
		},
	},
	methods: {
		isActive(item) {
			return this.$route.name === item.to.name;
		},
		async updateUnreadIssues(){
			this.lastUpdateAt = spacetime.now();

			const { data }= await this.$apollo.query({
				query: ISSUES,
				variables: { input: { id: this.id } },
				fetchPolicy: 'network-only',
			});
			this.numberOfUnreadIssues = this.$_commentMixin_unreadIssues(data.issues);
		}
	},
	mounted(){
		this.updateUnreadIssues();

		this.$root.$on('updated_lastAccessedTime', () => {
			this.updateUnreadIssues();
		});
		this.$root.$on('logged_in', () => {
			this.updateUnreadIssues();
		});

		setInterval(() => {
			if(this.lastUpdateAt.diff(spacetime.now(), 'milliseconds') > this.updateUnreadTimeThreshold){
				this.updateUnreadIssues();
			}
		}, this.updateUnreadTimeThreshold/2);
	},
};
</script>

<style scoped>
.nav-button {
	height: 56px !important;
	min-width: 0px !important;
}

.nav-button-text {
	font-size: 10px;
	font-size: 3vw;
}

@media screen and (min-width: 500px) {
	.nav-button-text {
		font-size: 14px;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
