<template>
	<apollo-query
		:query="query"
		:update="data => data.company"
		:fetchPolicy="'no-cache'"
	>
		<template v-slot="{ result: { loading, data } }">
			<v-app-bar
				app
				color="primary"
				dark
				elevate-on-scroll
				scroll-threshold="25"
			>
				<div class="container limited-width d-flex">
					<v-app-bar-nav-icon v-if="backButton" @click="$router.go(-1)">
						<v-icon>mdi-arrow-left</v-icon>
					</v-app-bar-nav-icon>
					<v-toolbar-title
						v-if="data"
						@click="goHome"
						style="cursor: pointer;"
						class="toolbar pl-0"
					>
						<v-img
							v-if="data.iconSmallUrl"
							:src="data.iconSmallUrl"
							class="logo mr-3"
						/>
						<div v-if="data.showNameInHeader" style="margin: auto;">
							{{ data.name }}
						</div>
					</v-toolbar-title>
				</div>
			</v-app-bar>
		</template>
	</apollo-query>
</template>

<script>
import { COMPANY } from '@/graphql';

export default {
	data: () => ({
		query: COMPANY,
		backButton: false,
	}),
	watch: {
		$route(to, _) {
			this.backButton =
				to.name !== 'login' && to.name !== 'home' && to.name !== 'noCompany';
		},
	},
	methods: {
		goHome() {
			if (this.$router.currentRoute.name !== 'home') {
				this.$router.push({ name: 'home' });
			}
		},
	},
};
</script>

<style>
.toolbar {
	display: flex;
	vertical-align: middle;
}

.logo {
	width: 40px;
	height: 40px;
}

/* Override Vuetify style to make it play nice with .limited-width */
.v-app-bar > .v-toolbar__content {
	padding: 0;
}
</style>
