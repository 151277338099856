<template>
	<v-container>
		<v-card>
			<v-card-title>Välj nytt lösenord</v-card-title>
			<v-form @submit="submit">
				<v-container class="pa-4">
					<a-form-input>
						<v-text-field
							v-model="password"
							:error-messages="passwordErrors"
							label="Lösenord"
							type="password"
							required
						/>
					</a-form-input>

					<a-form-input>
						<v-text-field
							v-model="repeatPassword"
							:error-messages="repeatPasswordErrors"
							label="Upprepa lösenordet"
							type="password"
							required
						/>
					</a-form-input>
				</v-container>

				<v-card-actions>
					<v-btn :to="{ name: 'login' }" text>
						Tillbaka
					</v-btn>
					<v-flex grow />
					<v-btn
						:loading="loading"
						:disabled="loading"
						type="submit"
						color="secondary"
					>
						Bekräfta
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-container>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import AFormInput from '@/components/FormInput';
import { RESET_PASSWORD } from '@/graphql';

export default {
	components: {
		AFormInput,
	},
	props: {
		token: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		loading: false,
		password: '',
		repeatPassword: '',
	}),
	computed: {
		passwordErrors() {
			const errors = [];
			if (!this.$v.password.$dirty) return errors;
			if (!this.$v.password.required) {
				errors.push('Lösenord krävs.');
			}

			if (!this.$v.password.minLength) {
				errors.push('Lösenordet måste vara minst 6 tecken långt.');
			}

			return errors;
		},
		repeatPasswordErrors() {
			const errors = [];
			if (!this.$v.repeatPassword.$dirty) return errors;
			if (!this.$v.repeatPassword.sameAsPassword) {
				errors.push('Du har angett två olika lösenord.');
			}

			return errors;
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			if (!this.$v.$invalid) this.resetPassword();
		},
		async resetPassword() {
			const { password } = this;
			const { repeatPassword } = this;
			this.loading = true;

			this.$apollo
				.mutate({
					mutation: RESET_PASSWORD,
					variables: {
						input: {
							password,
							repeatPassword,
							token: this.token,
						},
					},
				})
				.then(() => {
					this.$toast.success('Ditt lösenord har ändrats.');
					this.$router.push({ name: 'login' });
				})
				.catch(error => {
					this.password = password;
					this.repeatPassword = repeatPassword;
					this.loading = false;
					this.$v.$reset();
					this.$toast.error(error.graphQLErrors[0].message);
				});
		},
	},
	validations: {
		password: {
			required,
			minLength: minLength(6),
		},
		repeatPassword: {
			sameAsPassword: sameAs('password'),
		},
	},
	metaInfo: {
		title: 'Återställ lösenord',
	},
};
</script>

<style></style>
