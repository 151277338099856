<template>
	<v-container>
		<v-scroll-x-reverse-transition leave-absolute>
			<v-form
				v-show="!emailChecked"
				class="pa-0"
				@submit.prevent="submitEmail()"
			>
				<v-card-title tag="h1">Logga in</v-card-title>
				<v-container class="pa-4">
					<a-form-input>
						<v-text-field
							v-model="email"
							:error-messages="emailErrors"
							:disabled="disabled"
							label="E-postadress"
							type="text"
							required
						/>
					</a-form-input>
				</v-container>
				<v-card-actions>
					<v-flex grow />
					<v-btn
						:loading="loading"
						:disabled="loading"
						color="secondary"
						type="submit"
					>
						Nästa
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-scroll-x-reverse-transition>

		<v-scroll-x-reverse-transition leave-absolute>
			<v-form v-show="emailChecked" class="pa-0" @submit.prevent="submitAll()">
				<v-container class="pa-4">
					<a-form-input>
						<v-text-field
							v-model="email"
							style=""
							:error-messages="emailErrors"
							disabled
							background-color="grey lighten-4"
							type="text"
							required
						>
							<template #prepend-inner>
								<v-icon color="success">mdi-check</v-icon>
							</template>
						</v-text-field>
						<span class="mx-auto">Skriv in ditt lösenord</span>

						<v-text-field
							v-model="password"
							:type="showPassword ? 'text' : 'password'"
							:error-messages="passwordErrors"
							:disabled="disabled"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							label="Lösenord"
							required
							counter
							@click:append="showPassword = !showPassword"
						/>
					</a-form-input>
				</v-container>
				<v-card-actions>
					<v-btn :to="{ name: 'requestPasswordReset' }" text>
						Glömt lösenord
					</v-btn>
					<v-flex grow />
					<v-btn
						:loading="loading"
						:disabled="loading"
						type="submit"
						color="secondary"
					>
						Logga in
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-scroll-x-reverse-transition>
	</v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { LOGIN, CHECK_USER_EMAIL_LOGIN } from '@/graphql';
import AFormInput from '@/components/FormInput';

export default {
	components: {
		AFormInput,
	},
	props: {
		disabled: {
			type: Boolean,
		},
		prefilledEmail: {
			type: String,
			default: () => '',
		},
	},
	data: () => ({
		email: process.env.NODE_ENV === 'production' ? '' : 'test4@test.com',
		password: process.env.NODE_ENV === 'production' ? '' : 'test4',
		loading: false,
		showPassword: false,
		emailMatch: true,
		emailChecked: false,
		emailErrors: [],
		passwordErrors: [],
	}),
	methods: {
		async submitEmail() {
			this.emailChecked = await this.checkEmail();
		},
		submitAll() {
			this.passwordErrors = [];
			this.$v.$touch();
			if (!this.$v.password.required) {
				this.passwordErrors.push('Lösenord krävs');
				return;
			}

			if (!this.$v.$invalid) this.login();
		},
		async checkEmail() {
			this.emailErrors = [];
			this.$v.$reset();
			this.$v.email.$touch();
			if (!this.$v.email.required) {
				this.emailErrors.push('E-postadress krävs');
				return false;
			}

			if (!this.$v.email.email) {
				this.emailErrors.push('Måste vara en giltig e-postadress');
				return false;
			}

			this.loading = true;
			const { data } = await this.$apollo
				.query({
					query: CHECK_USER_EMAIL_LOGIN,
					variables: {
						input: {
							email: this.email,
						},
					},
					fetchPolicy: 'no-cache',
				})
				.catch(error => {
					this.emailErrors.push(error.graphQLErrors[0].message);
					this.loading = false;
					return false;
				});
			this.loading = false;

			if (data?.exists) {
				return true;
			}

			// Should not happen, the query returns either true or throws an error
			this.emailErrors.push('Någonting gick fel');
			return false;
		},
		async login() {
			const email = this.email.toLowerCase();
			const { password } = this;
			const { loading } = this;
			this.loading = true;

			await this.$apollo
				.mutate({
					mutation: LOGIN,
					variables: {
						input: {
							email,
							password,
						},
					},
					update: (store, { data: { login } }) => {
						store.writeData({
							data: {
								me: {
									...login,
									loggedIn: true,
								},
								issues: [],
							},
						});
					},
				})
				.then(({ data: { login } }) => {
					if (login) {
						this.$gtm.trackEvent({
							event: 'login',
						});
					}
				})
				.catch(error => {
					this.emailMatch = false;
					this.email = email;
					this.password = password;
					this.loading = loading;
					this.passwordErrors.push(error.graphQLErrors[0].message);
					this.$v.$reset();
				});
			this.$root.$emit('logged_in');
		},
	},
	validations: {
		email: {
			required,
			email,
		},
		password: {
			required,
		},
	},
};
</script>
