import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import router from '../router';

const initGTM = () => {
	Vue.use(VueGtm, {
		id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
		debug: process.env.NODE_ENV === 'development', // Whether or not display console logs debugs (optional)
		vueRouter: router, // Pass the router instance to automatically sync with router (optional)
		enabled: false, // Changes to true when analytic cookies are accepted
	});
};

export default initGTM;
