<template>
	<v-card class="mb-auto mx-auto" max-width="800">
		<v-card-text class="text--primary text-center">
			<v-avatar color="secondary" size="100">
				<span class="white--text headline">404</span>
			</v-avatar>
			<div class="mt-3">
				Det finns tyvärr inget företag registrerat på angiven url.
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {};
</script>

<style></style>
