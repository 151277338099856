<template>
	<v-container v-if="!loggedIn">
		<v-card>
			<v-img :src="media" :aspect-ratio="16 / 9" class="white--text" />

			<a-login-form :prefilled-email="email" />
		</v-card>
	</v-container>
</template>

<script>
import ALoginForm from '@/components/LoginForm';

export default {
	name: 'LoginCard',
	components: { ALoginForm },
	props: {
		logoUrl: {
			type: String,
			default: () => '',
		},
		loggedIn: {
			type: Boolean,
		},
	},
	data: () => ({
		email: '',
		counter: 0,
	}),
	computed: {
		media() {
			if (this.logoUrl === '') {
				return 'https://source.unsplash.com/YXemfQiPR_E';
			}

			return this.logoUrl;
		},
	},
};
</script>

<style></style>
