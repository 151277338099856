<template>
	<div id="updaterDiv" />
</template>

<script>
export default {
	name: 'TheUpdater',
	data: () => ({
		showPrompt: false,
		registration: null,
	}),
	created() {
		document.addEventListener('swu', this.refreshUI, { once: true });

		if (navigator.serviceWorker) {
			navigator.serviceWorker.addEventListener('controllerchange', function() {
				if (this.refreshing) {
					return;
				}

				this.refreshing = true;
				window.location.reload();
			});
		}
	},
	methods: {
		refreshUI(event) {
			event.preventDefault();

			this.registration = event.detail;

			if (!this.registration || !this.registration.waiting) {
				return;
			}

			this.registration.waiting.postMessage('skipWaiting');
		},
	},
};
</script>

<style></style>
