import { persistCache } from 'apollo-cache-persist';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App';
import './RegisterServiceWorker'; // eslint-disable-line import/no-unassigned-import
import router from './router';
import vuetify from '@/plugins/vuetify';
import { apolloClient, apolloProvider, cache } from '@/graphql';
import initGTM from './plugins/gtm';
import './global.css'; // eslint-disable-line import/no-unassigned-import

Vue.config.productionTip = false;

Vue.use(Vuelidate);

const startApp = async () => {
	const initData = {
		me: {
			__typename: 'User',
			loggedIn: false,
		},
	};
	apolloClient.writeData({ data: initData });
	apolloClient.onResetStore(async () => cache.writeData({ data: initData }));

	await persistCache({
		cache,
		storage: window.localStorage,
	});

	initGTM();

	new Vue({
		router,
		vuetify,
		apolloProvider,
		render: h => h(App),
	}).$mount('#app');
};

startApp();
