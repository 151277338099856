import Vue from 'vue';
import Meta from 'vue-meta';
import Router from 'vue-router';
import { apolloClient, LOGGED_IN, ACTIVE_CLIENT } from './graphql';
import Login from './views/Login';
import ResetPassword from './views/ResetPassword';
import VerifyEmail from './views/VerifyEmail';
import NoCompany from './views/NoCompany';

Vue.use(Router);
Vue.use(Meta);

const loggedIn = async () => {
	const data = apolloClient.readQuery({ query: LOGGED_IN });
	if (data && data.me.loggedIn) {
		return true;
	}

	return false;
};

const isActive = async () => {
	// Check if user is active in TW.
	const data = await apolloClient.query({
		query: ACTIVE_CLIENT,
		fetchPolicy: 'no-cache',
	});

	// Ensure status not null
	const status = data.data.me?.client.status;
	if (status) {
		return status === 'active';
	}

	return false;
};

const authenticationGuard = async (next, to) => {
	const authenticated = await loggedIn();
	if (!authenticated) {
		next({ name: 'login' });
		return;
	}

	const active = await isActive();
	if (!active && to.name !== 'logout') {
		next({ name: 'logout' });
		return;
	}

	next();
};

const lazyLoad = view => () => import(`@/views/${view}.vue`);

export default new Router({
	base: '/',
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'register',
			component: Login,
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
		},
		{
			path: '/verifyEmail/:token',
			name: 'verifyEmail',
			component: VerifyEmail,
			props: true,
		},
		{
			path: '/resetPassword/:token',
			name: 'resetPassword',
			component: ResetPassword,
			props: true,
		},
		{
			path: '/requestPasswordReset',
			name: 'requestPasswordReset',
			component: lazyLoad('RequestPasswordReset'),
		},
		{
			path: '/home',
			name: 'home',
			component: lazyLoad('Home'),
			beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/account',
			name: 'account',
			component: lazyLoad('Account'),
			beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/kundtjanst',
			name: 'company',
			component: lazyLoad('Company'),
			beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/issues',
			name: 'issues',
			component: lazyLoad('Issues'),
			beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/issue/:id',
			name: 'issue',
			props: true,
			component: lazyLoad('Issue'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/invoices',
			name: 'invoices',
			component: lazyLoad('Invoices'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/invoice/:id',
			name: 'invoice',
			props: true,
			component: lazyLoad('Invoice'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/boka',
			name: 'book',
			component: lazyLoad('Book'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/bokningar',
			name: 'bookings',
			component: lazyLoad('Bookings'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/bokning/:id',
			name: 'booking',
			props: true,
			component: lazyLoad('Booking'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/logout',
			name: 'logout',
			component: lazyLoad('Logout'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next, to);
			},
		},
		{
			path: '/404',
			name: 'noCompany',
			component: NoCompany,
		},
		{
			path: '*',
			beforeEnter: (to, from, next) => {
				next('/');
			},
		},
	],
});
